/* DataViewDemo.css */

.dataview,
.occurrence-container{
    width: 90%;
    max-width: 1280px;
    min-width: 500px;
    margin: 0 auto;
}

.occurrence-container{
    max-width: 1360px!important;
}

/*.dataview .p-datatable-thead*/
.hidden-header .p-datatable-thead{
    display: none;
}

.dataview .p-dataview-header,
.occurrence-container .p-dataview-header{
    margin: 0 auto 20px auto;
    display: flow-root;
}
.dataview .p-dataview-header h1,
.occurrence-container .p-dataview-header h1{
    float: left;
    margin: 0;
}
.dataview .p-dataview-header button,
.occurrence-container .p-dataview-header button{
    float: right;
}

.dataview .p-dropdown,
.occurrence-container .p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.dataview .product-name,
.occurrence-container .product-name {
    font-size: 1.2rem;
    font-weight: 700;
}

.dataview .product-user-count {
    /*width: 100px;*/
}

.dataview .product-description {
    margin: 0 0 1rem 0;
}

.dataview .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.dataview .product-category {
    font-weight: 600;
    vertical-align: middle;
}

.dataview .product-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.dataview .product-list-item img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
}

.dataview .product-list-item .product-list-detail {
    flex: 1 1 0;
}

.dataview .product-list-item .p-rating {
    margin: 0 0 .5rem 0;
}

.dataview .product-list-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
    align-self: flex-end;
}

.dataview .product-list-item .product-list-action {
    /*display: flex;*/
    /*flex-direction: column;*/
}

.dataview .product-list-item .p-button {
    margin-bottom: .5rem;
}

.dataview .product-grid-item {
    margin: .5em;
    border: 1px solid var(--surface-border);
    padding: 2rem;
}

.dataview .product-grid-item .product-grid-item-top,
.dataview .product-grid-item .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dataview .product-grid-item img {
    width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
}

.dataview .product-grid-item .product-grid-item-content {
    text-align: center;
}

.dataview .product-grid-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
}

@media screen and (max-width: 576px) {
    .dataview .product-list-item {
        flex-direction: column;
        align-items: center;
    }

    .dataview .product-list-item img {
        width: 75%;
        margin: 2rem 0;
    }

    .dataview .product-list-item .product-list-detail {
        text-align: center;
    }

    .dataview .product-list-item .product-price {
        align-self: center;
    }

    .dataview .product-list-item .product-list-action {
        display: flex;
        flex-direction: column;
    }

    .dataview .product-list-item .product-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

.edit-category-dialog .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label),
.create-user-dialog .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label),
.edit-user-dialog .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.edit-category-dialog .multiselect-custom .user-item-value,
.create-user-dialog .multiselect-custom .user-item-value,
.edit-user-dialog .multiselect-custom .user-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}