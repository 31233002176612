/* FormDemo.css */
#captcha > div > div{
    width:auto!important;
}
.form-holder .card {
    width: 100%;
    max-width: 450px;
    margin: auto;
    text-align: center;
}
.form-holder .card h1 {
    margin-top: 5px;
}
.form-holder .card .forgot-password {
    line-height: 45px;
    vertical-align: middle;
}
.p-float-label input:-webkit-autofill ~ label {
    top: -10px!important;
    font-size: 14px;
}
.form-holder .card form {
    margin-top: 2rem;
}
.form-holder .card .field,
.form-holder .card .field-checkbox {
    margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
    .form-holder .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-holder .card {
        width: 100%;
        min-width: 0;
    }
}

.wid10{width:10%!important;}
.wid20{width:20%!important;}
.wid25{width:25%!important;}
.wid30{width:30%!important;}
.wid35{width:35%!important;}
.wid40{width:40%!important;}
.wid45{width:45%!important;}
.wid50{width:50%!important;}
.wid60{width:60%!important;}
.wid70{width:70%!important;}
.wid80{width:80%!important;}
.wid90{width:90%!important;}
.wid95{width:95%!important;}
.wid100{width:100%!important;}

.floatNone{float:none}
.floatLeft{float:left}
.floatRight{float:right}
.clearBoth{clear: both}

.marginAuto{margin:auto}

.textDecorationNone{text-decoration: none;}

.wb-container {padding:50px 0; width:40%; min-width: 800px; margin: 0 auto; border-top: #14b9a6 5px solid;}
.wb-container p {margin: 0;}
.wb-container span .p-inputtext {margin-top: 5px;}

.spinner-container{
    position: absolute;
    z-index: 100000;
    background-color: white;
    padding: 40vh 95vh;
    text-align: center;
}